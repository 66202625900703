html,
body {
    height: 100%;
    /* background: #fafafa;  */
    background: #212121;
    overflow-x: hidden;

    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    color: #fafafa;
    /* font-weight: 200; */
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
}

a {
    color: #cecece;
}

a:hover {
    color: #acacac;
}

.container {
    width: 80%;
    margin: auto;
    margin-top: 1rem;
    min-height: 80vh;
    overflow-y: visible !important;
}

.text-center {
    text-align: center !important;
}

.text-dark {
    color: #212121 !important;
}

.text-light {
    color: #fafafa !important;
}

.light-card {
    background: #fefefe !important;
}

.text-muted {
    font-size: 0.80rem;
    font-weight: 200;
    font-style: italic;
    color: #bababa;
}

.text-light-faded {
    font-weight: 200;
    color: rgba(255, 255, 255, 0.75);
}

@media only screen and (max-width: 600px) {}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 992px) {
    .container {
        width: unset;
        margin: 0 1rem;
    }

}

@media only screen and (max-width: 1200px) {}