.w-100 {
    width: 100% !important;
}

.m-0 {
    margin: unset !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.ml-3 {
    margin-left: 1rem !important;
}

.mr-3 {
    margin-right: 1rem !important;
}

.m-1 {
    margin: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.m-auto {
    margin: auto;
}

.p-0 {
    padding: 0 !important;
}

.p-3 {
    padding: 1rem !important;
}

.pl-3 {
    padding-left: 1rem !important;
}

.pr-3 {
    padding-right: 1rem !important;
}

.float-right {
    float: right !important;
}

.float-left {
    float: left !important;
}

@media only screen and (max-width: 992px) {
    .mobile-hide {
        display: none !important;
    }
}